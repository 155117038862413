.browser-compatibility {
  padding:1rem;
  background:#F00;
  color:white;
  font-weight:bold;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
          justify-content:center;
  -webkit-align-content:center;
          align-content:center;
  -webkit-flex-wrap:1;
          flex-wrap:1;
  width:100vw;
  box-sizing:border-box;
  font-size:1.5rem;
}



.btn-like, .btn-dislike {
  border: 0;
  background: transparent; }

.btn-like.voted {
  color: green; }

.btn-dislike.voted {
  color: red; }

.container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product_item .product_item--info-inner {
  padding-bottom: 25px;
}

.product_item .product_item--info {
  padding-top: 25px;
}

.product_item .product_item--inner {
  margin-bottom: 35px;
}

.categorySelected {
  text-decoration: underline;
}

.winners-category:hover {
  text-decoration: underline;
}
.container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product_item .product_item--info-inner {
  padding-bottom: 25px;
}

.product_item .product_item--info {
  padding-top: 25px;
}

.product_item .product_item--inner {
  margin-bottom: 35px;
}
