.btn-like,.btn-dislike {
  border:0;
  background:transparent;
}
.btn-like.voted {
  color:green;
}
.btn-dislike.voted {
  color:red;
}
