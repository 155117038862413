.browser-compatibility {
  padding:1rem;
  background:#F00;
  color:white;
  font-weight:bold;
  display:flex;
  justify-content:center;
  align-content:center;
  flex-wrap:1;
  width:100vw;
  box-sizing:border-box;
  font-size:1.5rem;
}
