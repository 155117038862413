.container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product_item .product_item--info-inner {
  padding-bottom: 25px;
}

.product_item .product_item--info {
  padding-top: 25px;
}

.product_item .product_item--inner {
  margin-bottom: 35px;
}